<template>
  <div class="d-flex justify-content-between room-information">
    <div class="form-group">
      <label v-if="idx == '0'">{{ $t('booking.no-nights') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="0"
        readonly
        :value="NoNight"
      />
    </div>
    <div class="form-group icon" v-if="roomType">
      <label v-if="idx == '0'"
        >{{ $t('booking.room') }} & {{ $t('booking.guest') }}</label
      >
      <input
        type="text"
        class="form-control"
        placeholder="1 room, 2 adults"
        readonly
        v-model="roomType"
      />
      <div class="icon-form"><i class="fas fa-users"></i></div>
    </div>
    <div class="form-group icon" v-if="roomBasis">
      <label v-if="idx == '0'">{{ $t('booking.hosting-base') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="2 bed, breakfast"
        readonly
        v-model="roomBasis"
      />
      <div class="icon-form"><i class="fas fa-bed"></i></div>
    </div>
    <div class="form-group" v-if="roomClass">
      <label v-if="idx == '0'">{{ $t('booking.suite') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="0"
        readonly
        v-model="roomClass"
      />
    </div>
    <div class="form-group icon">
      <label v-if="idx == '0'">{{ $t('booking.departure-date') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="01-01-2021"
        readonly
        v-model="departure"
      />
      <div class="icon-form"><i class="far fa-calendar-alt"></i></div>
    </div>
    <div class="form-group icon">
      <label v-if="idx == '0'">{{ $t('booking.arrival-date') }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="01-01-2021"
        readonly
        v-model="arrival"
      />
      <div class="icon-form"><i class="far fa-calendar-alt"></i></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    idx: {
      type: Number,
      default: 1,
    },
    room: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      departure: '00-00-000',
      arrival: '00-00-0000',
      NoNight: 0,
      roomClass: '',
      roomType: '',
      roomBasis: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    this.departure = this.room.checkIn;
    this.arrival = this.room.checkOut;
    this.NoNight = this.room.NoNight;
    this.ct = -1;
    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      this.roomClass = this.room.translations?.Room_Class[this.room.roomClass.value] ? this.room.translations.Room_Class[this.room.roomClass.value][this.lang] : (this.room.roomClass && this.room.roomClass.text);
      this.roomType = this.room.translations?.Room_Type[this.room.roomType.value] ? this.room.translations.Room_Type[this.room.roomType.value][this.lang] : (this.room.roomType && this.room.roomType.value);
      this.roomBasis = this.room.translations?.basisName[this.room.roomBasisCode.value] ? this.room.translations.basisName[this.room.roomBasisCode.value][this.lang] : this.room.roomBasis;
    },
  },
};
</script>

<style scoped>
.room-information {
  font-weight: bold;
  box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
}
.price_details_body .form-group {
  position: relative;
  width: 100%;
  margin: 2px 5px;
}

.form-group label {
  font-size: 15px;
  line-height: 0.6rem;
}

.price_details_body .form-group .form-control {
  height: 42px;
}

.price_details_body .form-group.icon .form-control {
  padding-left: 40px;
}

.form-control {
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  direction: 'ltr';
}

.price_details_body .form-group .icon-form {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 14px;
  color: #b0b0b0;
}
.btnDomestic {display:none !important;}
.btn[class*="btnDomestic"] {display:none !important;}
@media (max-width: 767.98px) {
  div.d-flex.justify-content-between {
    display: inline !important;
  }

  .form-group label {
    display: none;
  }

  div.d-flex.justify-content-between > .form-group {
    width: 50%;
    display: inline-flex !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px;
  }

  div.d-flex.justify-content-between > .form-group:last-child {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .form-group label {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .form-group label {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .form-group label {
    font-size: 18px;
  }
}
</style>
